.particle-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 0;
  pointer-events: none;
  opacity: 0.5;
  transition: opacity 0.3s ease;
  background-color: transparent;
}

/* Dark theme adjustments */
[data-theme="dark"] .particle-background {
  opacity: 0.1;
}

/* Mobile optimizations */
@media screen and (max-width: 768px) {
  .particle-background {
    opacity: 0.25;
  }
  
  .particle-background.mobile {
    /* This class will be added via JS */
  }
}

/* Small mobile devices */
@media screen and (max-width: 480px) {
  .particle-background {
    opacity: 0.2;
  }
}

/* Reduce motion if user prefers */
@media (prefers-reduced-motion: reduce) {
  .particle-background {
    opacity: 0.1;
  }
} 