.notification-container {
  position: fixed;
  top: 2rem;
  right: 5rem;
  z-index: 1000;
}

.notification-button {
  background: none;
  border: none;
  font-size: 2rem;
  color: var(--nav-color);
  cursor: pointer;
  padding: 0.5rem;
  transition: transform 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.notification-badge {
  position: absolute;
  top: 0;
  right: 0;
  background-color: var(--title-color);
  color: var(--current-fg);
  font-size: 0.8rem;
  padding: 0.2rem 0.4rem;
  border-radius: 50%;
  min-width: 1.2rem;
  height: 1.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Handjet', cursive;
}

.notification-button.ringing {
  animation: ring 1s ease-in-out infinite;
}

@keyframes ring {
  0%, 100% {
    transform: rotate(0);
  }
  20%, 60% {
    transform: rotate(-10deg);
  }
  40%, 80% {
    transform: rotate(10deg);
  }
}

.notification-popup {
  position: absolute;
  top: calc(100% + 1rem);
  right: 0;
  background-color: var(--current-fg);
  border: 3px dotted var(--nav-color);
  padding: 1.5rem;
  min-width: 300px;
  max-width: 400px;
  opacity: 0;
  visibility: hidden;
  transform: translateY(-10px);
  transition: all 0.3s ease;
}

.notification-popup.open {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

.notification-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  font-family: 'Handjet', cursive;
}

.notification-header h3 {
  color: var(--nav-color);
  font-size: 1.5rem;
  margin: 0;
}

.clear-button {
  font-family: 'Handjet', cursive;
  font-size: 1rem;
  color: var(--nav-color);
  background: none;
  border: 2px solid var(--nav-color);
  padding: 0.3rem 0.8rem;
  cursor: pointer;
  transition: all 0.3s ease;
}

.clear-button:hover {
  background-color: var(--nav-color);
  color: var(--current-fg);
}

.notification-list {
  max-height: 300px;
  overflow-y: auto;
}

.notification-item {
  padding: 1rem;
  border: 2px solid transparent;
  margin-bottom: 0.5rem;
  font-family: 'Handjet', cursive;
  transition: all 0.3s ease;
}

.notification-item.unread {
  border-color: var(--nav-color);
}

.notification-item h4 {
  color: var(--title-color);
  margin: 0 0 0.5rem 0;
  font-size: 1.2rem;
}

.notification-item p {
  color: var(--nav-color);
  margin: 0;
  font-size: 1rem;
  line-height: 1.4;
}

.notification-time {
  display: block;
  font-size: 0.9rem;
  color: var(--nav-color);
  opacity: 0.7;
  margin-top: 0.5rem;
}

.no-notifications {
  text-align: center;
  color: var(--nav-color);
  font-family: 'Handjet', cursive;
  font-size: 1.2rem;
  padding: 1rem;
}

@media screen and (max-width: 768px) {
  .notification-container {
    top: 1.5rem;
    right: 4.5rem;
  }

  .notification-button {
    font-size: 1.8rem;
  }

  .notification-popup {
    min-width: 280px;
    max-width: 320px;
    right: -50px;
    padding: 1.2rem;
    max-height: 80vh;
    overflow-y: auto;
  }

  .notification-header {
    flex-direction: column;
    gap: 1rem;
    align-items: stretch;
  }

  .notification-header h3 {
    font-size: 1.8rem;
    text-align: center;
  }

  .clear-button {
    font-size: 1.2rem;
    padding: 0.5rem;
    width: 100%;
    text-align: center;
    margin-bottom: 0.5rem;
  }

  .notification-popup {
    left: auto;
    right: -40px;
    width: calc(100vw - 4rem);
    max-width: 320px;
  }
}

@media screen and (max-width: 480px) {
  .notification-container {
    top: 1rem;
    right: 4rem;
  }

  .notification-button {
    font-size: 1.6rem;
  }

  .notification-popup {
    right: -30px;
    padding: 1rem;
    min-width: unset;
    width: calc(100vw - 3rem);
    max-width: 300px;
  }

  .notification-header h3 {
    font-size: 1.6rem;
  }

  .clear-button {
    font-size: 1.1rem;
    padding: 0.4rem;
  }

  .notification-item {
    padding: 0.8rem;
  }

  .notification-item h4 {
    font-size: 1.3rem;
  }

  .notification-item p {
    font-size: 1.1rem;
  }
}

@media screen and (max-width: 350px) {
  .notification-container {
    right: 3.5rem;
  }

  .notification-popup {
    right: -20px;
    width: calc(100vw - 2.5rem);
    padding: 0.8rem;
  }

  .notification-header h3 {
    font-size: 1.4rem;
  }

  .clear-button {
    font-size: 1rem;
    padding: 0.3rem;
  }
}

.notification-item.clickable {
  cursor: pointer;
  transition: transform 0.2s ease;
}

.notification-item.clickable:hover {
  transform: translateY(-2px);
  background-color: var(--current-bg);
}

/* Touch device optimizations */
@media (hover: none) {
  .notification-button,
  .clear-button,
  .notification-item.clickable {
    -webkit-tap-highlight-color: transparent;
  }

  .notification-list {
    -webkit-overflow-scrolling: touch;
  }

  .notification-popup {
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
  }

  .notification-item {
    padding: 1rem;
    margin-bottom: 0.8rem;
  }

  .clear-button {
    padding: 0.5rem 1rem;
  }
}

/* Handle notch devices */
@supports (padding-top: env(safe-area-inset-top)) {
  .notification-container {
    padding-top: env(safe-area-inset-top);
    padding-right: env(safe-area-inset-right);
  }
}

/* Add better touch handling */
@media (hover: none) {
  .notification-popup {
    -webkit-overflow-scrolling: touch;
  }

  .clear-button {
    min-height: 44px;
  }

  .notification-item {
    min-height: 44px;
  }
}

/* Handle notched devices */
@supports (padding: env(safe-area-inset-right)) {
  .notification-popup {
    padding-right: max(1rem, env(safe-area-inset-right));
    padding-left: 1rem;
  }
} 