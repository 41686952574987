.projects-container {
  padding: 2rem;
  min-height: 100vh;
  background-color: var(--current-bg);
  color: var(--current-fg);
}

.title-container {
  text-align: center;
  margin-bottom: 3rem;
}

.projects-title {
  font-family: "Handjet", cursive;
  font-size: 3rem;
  color: var(--title-color);
  margin: 0;
}

.projects-section {
  margin-bottom: 4rem;
}

.section-title {
  font-family: "Handjet", cursive;
  font-size: 2rem;
  color: var(--nav-color);
  margin-bottom: 2rem;
  text-align: center;
}

.projects-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
  max-width: 1200px;
  margin: 0 auto;
}

.project-card {
  background-color: var(--current-fg);
  border: 3px dotted var(--nav-color);
  border-radius: 10px;
  overflow: hidden;
  transition: transform 0.3s ease;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.project-card:hover {
  transform: translateY(-5px);
}

.project-image-container {
  width: 100%;
  height: 200px;
  overflow: hidden;
}

.project-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.project-image-top {
  object-position: top;
}

.project-card:hover .project-image {
  transform: scale(1.05);
}

.project-content {
  padding: 1.5rem;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.project-title {
  font-family: "Handjet", cursive;
  font-size: 1.5rem;
  color: var(--title-color);
  margin: 0 0 1rem 0;
}

.project-description {
  font-family: "Handjet", cursive;
  font-size: 1.3rem;
  color: var(--nav-color);
  margin: 0 0 1rem 0;
  flex-grow: 1;
}

.project-tags {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-bottom: 1rem;
}

.tag {
  font-family: "Handjet", cursive;
  font-size: 1rem;
  color: var(--nav-color);
  background-color: var(--current-bg);
  padding: 0.4rem 1rem;
  border-radius: 15px;
  border: 1px solid var(--nav-color);
}

.project-button {
  font-family: "Handjet", cursive;
  font-size: 1rem;
  color: var(--current-fg);
  background-color: var(--nav-color);
  border: none;
  padding: 0.8rem 1.5rem;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
  align-self: flex-start;
}

.project-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.more-projects {
  text-align: center;
  margin-top: 4rem;
  padding-bottom: 2rem;
}

.more-projects-button {
  font-family: 'Handjet', cursive;
  font-size: 2rem;
  font-weight: 500;
  color: var(--nav-color);
  background: none;
  border: 5px dotted var(--nav-color);
  padding: 1rem 2rem;
  cursor: pointer;
  transition: all 0.3s ease;
}

.more-projects-button:hover {
  background-color: var(--nav-color);
  color: var(--white-color);
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--current-bg);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  backdrop-filter: blur(5px);
  animation: fadeIn 0.3s ease-in-out;
}

.modal-content {
  background-color: var(--current-fg);
  padding: 3rem;
  border: 5px dotted var(--nav-color);
  position: relative;
  max-width: 90%;
  width: 600px;
  text-align: center;
  animation: slideUp 0.3s ease-in-out;
}

.modal-close {
  position: absolute;
  top: 1rem;
  right: 1rem;
  font-size: 2rem;
  background: none;
  border: none;
  color: var(--nav-color);
  cursor: pointer;
  padding: 0.5rem;
  line-height: 1;
}

.modal-image {
  max-width: 100%;
  height: auto;
  border-radius: 10px;
  margin-bottom: 2rem;
}

.modal-text {
  font-family: 'Handjet', cursive;
  font-size: 1.5rem;
  color: var(--nav-color);
  margin-bottom: 2rem;
  line-height: 1.4;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideUp {
  from {
    transform: translateY(50px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@media screen and (max-width: 768px) {
  .projects-container {
    padding: 1rem;
  }

  .projects-title {
    font-size: 2.5rem;
  }

  .section-title {
    font-size: 1.8rem;
  }

  .projects-grid {
    grid-template-columns: 1fr;
    gap: 1.5rem;
  }

  .project-image-container {
    height: 180px;
  }

  .project-content {
    padding: 1.2rem;
  }

  .project-title {
    font-size: 1.3rem;
  }

  .project-description {
    font-size: 1.1rem;
  }

  .tag {
    font-size: 0.9rem;
    padding: 0.3rem 0.8rem;
  }

  .project-button {
    font-size: 0.9rem;
    padding: 0.6rem 1.2rem;
  }
}

@media screen and (max-width: 480px) {
  .projects-title {
    font-size: 2rem;
  }

  .section-title {
    font-size: 1.5rem;
  }

  .project-image-container {
    height: 160px;
  }

  .project-content {
    padding: 1rem;
  }

  .project-title {
    font-size: 1.2rem;
  }

  .project-description {
    font-size: 1rem;
  }

  .tag {
    font-size: 0.9rem;
    padding: 0.3rem 0.8rem;
  }

  .project-button {
    font-size: 0.9rem;
    padding: 0.6rem 1.2rem;
  }
}

@media screen and (max-width: 350px) {
  .projects-title {
    font-size: 3.5rem;
  }

  .project-title {
    font-size: 2rem;
  }

  .project-description {
    font-size: 1.3rem;
  }

  .tag {
    font-size: 1.1rem;
  }

  .project-button {
    font-size: 1.3rem;
  }

  .more-projects-button {
    font-size: 1.6rem;
    padding: 0.7rem 1.4rem;
  }

  .modal-text {
    font-size: 1.3rem;
  }
}

/* Touch device optimizations */
@media (hover: none) {
  .project-card {
    transform: none;
  }

  .project-card:hover {
    transform: none;
  }

  .project-button, 
  .more-projects-button,
  .modal-close {
    -webkit-tap-highlight-color: transparent;
  }

  .modal-content {
    max-height: 90vh;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }
} 