.nav-container {
  position: fixed;
  bottom: 50px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: clamp(1rem, 2vw, 3rem);
  z-index: 1000;
  white-space: nowrap;
}

/* New styles for page navigation (non-home) */
.page-nav {
  background-color: var(--current-fg);
  padding: 0.75rem 2rem;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  opacity: 0;
  transition: opacity 1s ease-in-out;
  pointer-events: none;
}

.page-nav.visible {
  opacity: 1;
  pointer-events: all;
}

.nav-link {
  font-family: 'Handjet', cursive;
  font-weight: 300;
  font-size: 2rem;
  color: var(--nav-color);
  text-decoration: none;
  padding-bottom: 5px;
}

.nav-link.active {
  border-bottom: 2px solid var(--nav-color);
}

.hamburger {
  display: none;
  flex-direction: column;
  gap: 5px;
  cursor: pointer;
  position: fixed;
  bottom: 30px;
  right: 30px;
  z-index: 1001;
  padding: 10px;
}

.hamburger span {
  display: block;
  width: 30px;
  height: 3px;
  background-color: var(--nav-color);
  transition: all 0.3s ease-in-out;
}

/* Mobile Navigation Styles */
@media screen and (max-width: 768px) {
  .hamburger {
    display: flex;
  }

  .nav-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--current-bg);
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
    backdrop-filter: blur(5px);
    z-index: 999;
  }

  .nav-overlay.open {
    opacity: 0.95;
    visibility: visible;
  }

  /* Remove background-color condition for home page */
  .nav-container {
    position: fixed;
    top: 0;
    right: -100%;
    bottom: 0;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: right 0.3s ease-in-out;
    gap: 2rem;
    visibility: hidden;
    opacity: 0;
    transition: right 0.3s ease-in-out, opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
  }

  .nav-container.open {
    right: 0;
    visibility: visible;
    opacity: 1;
  }

  .hamburger.open span:first-child {
    transform: rotate(45deg) translate(6px, 6px);
  }

  .hamburger.open span:nth-child(2) {
    opacity: 0;
  }

  .hamburger.open span:last-child {
    transform: rotate(-45deg) translate(6px, -6px);
  }

  .nav-link {
    font-size: 1.8rem;
  }
}

@media screen and (max-width: 480px) {
  .nav-link {
    font-size: 1.5rem;
  }
}

@media screen and (max-width: 350px) {
  .nav-container {
    gap: 1.5rem;
  }
  .nav-link {
    font-size: 1.2rem;
  }
} 